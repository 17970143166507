@use 'base';
@use "components";

:root{
    --azul : #08d9d68a;
    --negro : #252A34;
    --rojo : #ff2e627e;
    --gris : #EAEAEA;

}
.bg-azul{
    background-color: var(--azul);
}
.bg-negro{
    background-color: var(--negro);
}
.bg-rojo{
    background-color: var(--rojo);
}
.bg-gris{
    background-color: var(--gris);
}

.negrita {
    font-weight: bold;
}

// H