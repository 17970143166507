@use 'base/variables' as v;

.alerta {
    padding: calc(v.$separacion / 4);
    color: v.$blanco;
    width: 100%;
    display: block;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: v.$bold;
    text-transform: uppercase;

    &.error {
        background-color: v.$rojo;
    }
    &.exito {
        background-color: v.$verde;
    }
}