.ver-entrenamiento{
    height: 40px;
    width: 40px;
    margin-left: 5px;
}
.ver-entrenamiento i{
    font-size: 18px;
    color: white;
    padding: 0;
    margin: 0;
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: rgba(0,0,0,.5);

    .cuerpo{
        width: -webkit-fill-available;
        max-width: 1200px;
        height: 80%;
        background-color: white;
        border-radius: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .cuerpo h3{
        text-align: center;
        margin: 10px;
    }
    .cuerpo .tarjeta{
        gap: 10px;
    }
    .cuerpo .centrar{
        justify-content: center;
    }
    .cerrar,.regresar{
        margin: 10px auto;
    
    }

}


.Dashboard__link {
    text-decoration: none;
    color: black;
}