@use "base/mixins" as m;
@use "base/variables" as v;

.info_entrenamiento {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); 
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.86);
  margin: 1.5rem auto;
  padding: 1.4rem;
  border-radius: 1.5rem;
}
.atributo_entrenamiento {
  font-size: 1.4rem;
  text-align: center;
  font-weight: v.$black;
}
.info_entrenamiento {
  font-size: 1.2rem;
  text-align: center;
}

.modal_ver_entrenamiento{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1039;

    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: rgba(46, 46, 46, 0.466);

    .cuerpo{
        width: -webkit-fill-available;
        max-width: 1200px;
        height: 80%;
        background-color: white;
        border-radius: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .cuerpo h3{
        text-align: center;
        margin: 10px;
    }
    .cuerpo .tarjeta{
        gap: 10px;
    }
    .cuerpo .centrar{
        justify-content: center;
    }
    .cerrar,.regresar{
        margin: 10px auto;
    
    }

}
