@use 'base/mixins' as m;

.estudiantesEntrenadosAct {
    text-align: center;
    margin-bottom: 2rem;

    section {
        margin-top: 1rem;

        div {
            border-style: dashed;
            border-color: blue;
            padding: 0.5rem;
            padding-bottom: 0;
        }
    }
}

.barraBuscadora__fieldsetAlumnosEntrenados {
    width: 100%;
    display: flex;
}
