@use "base/mixins" as m;
@use "base/variables" as v;

/* Accordion */

@include m.tablet {
  .acordeon_header {
    display: flex;
    gap: 10px;
  }
}
.acordeon_header {
  div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
  .alumno {
    display: grid;
    place-items: center;
    background-color: #17a2b8;
    max-width: 25rem;
    p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 1.2rem;
        text-align: center;
        text-transform: capitalize;
        color: v.$blanco;
        @include m.tablet {
            font-size: 1.6rem;
          }
    }
    
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  @include m.telefono {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
}
.botones_acordeon {
  .fa-check-to-slot {
    @include m.desktop {
      font-size: 1.8rem;
    }
  }
  button {
    max-height: 3.5rem;
  }
  .asignarRutina__texto,
  .verEntrenamiento__texto {
    display: none;
    @include m.desktop {
      display: inline;
    }
  }
}

.accordion__datosElementales {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .accordion__datosElementales {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
}

.accordion__datosElementales p {
  text-align: center;
}

.accordion__datosElementales__subtitulo {
  font-weight: bold;
}

.accordion__datosElementales__objetivos {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
}

@media (min-width: 992px) {
  .graficasAccordion {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    row-gap: 50px;
  }

  .grafica__asistencia,
  .grafica__pesoAlumno {
    max-width: 100%;
    margin: 0;
  }

  .espacioGrafica {
    grid-column: 1 / 3;
  }
}

@media (min-width: 1645px) {
  .grafica__asistencia,
  .grafica__pesoAlumno {
    max-width: 1057px;
    margin: 0;
  }
}

.graficas__texto,
.grafica__fueraAccordion__texto {
  text-align: center;
  font-weight: bold;
}

.grafica__fueraAccordion__texto {
  margin-top: 100px;
}

.grafica__fueraAccordion__lineaDivisioria {
  margin-top: 100px;
}
