@use 'base/mixins' as m;

.barraBuscadora {
    width: 90%;
    margin: 0 auto 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include m.desktop {
        position: relative;
    }
}

.barraBuscadora__fieldset {
    width: 100%;
    display: flex;

    @include m.desktop {
        position: absolute;
        top: 0;
    }
}

.barraBuscadora__legend {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.barraBuscadora__input {
    width: 90%;
    height: 50px;
    border: none;
}

.barraBuscadora__boton {
    background-color: royalblue;
    border-radius: 5px;
    border: 0px;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px 10px;
    height: 50px;
    width: 50px;
}

.barraBuscadora__boton:hover {
    background-color: #3e4144;
}

.barraBuscadora__boton:focus{ 
    outline: none;
}
