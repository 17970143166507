// Fuentes
$fuente_principal: 'Poppins', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores
$azul: #0da6f3;
$rojo: #cb0000;
$verde:#329f00;
$negro: #1a1b15;

//Colores de bootstrap
$primary: #0d6efd;
$indigo : #6610f2;
$red:#dc3545;
$orange: #fd7e14;
$yellow:#ffc107;
$cyan: #0dcaf0;
$grey: #adb5bd;

$blanco: #FFFFFF;
$gris: #e1e1e1;

$separacion: 5rem;

// Fuentes
$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900