.tarjeta{
    background-color: gris;
    padding: 30px;
    width: 300px;
    height: auto;
    border-radius: 28px 28px 28px 28px;
    -moz-border-radius: 28px 28px 28px 28px;
    -webkit-border-radius: 28px 28px 28px 28px;
    -webkit-box-shadow: 7px 7px 26px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 7px 7px 26px -10px rgba(0,0,0,0.75);
    box-shadow: 7px 7px 26px -10px rgba(0,0,0,0.75);
    border: 0px solid white;


}
.tarjeta i{
    color: var(--negro);
    font-size: 70px;
    transition: all 1s ease-in-out;
}
.tarjeta i:hover{
    transform: scale(1.3);
}