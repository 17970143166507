@use "base/mixins" as m;
@use "base/variables" as v;

.entrenados {
  width: 100%;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  h2 {
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }
}
.entrenados_parent {
  background-color: white;
  padding: 1.6rem;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  height: 20rem;
}
.buscador_entrenados {
  input {
    background-color: #f9f7f7;
    border: 1px solid #e1e1e1;
    width: 100%;
    height: 2.4rem;
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: .5rem;
  }
}
.contenedor_entrenados {
  height: 15rem;
  overflow-y: auto;
}
.entrenado {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 3rem;
  padding: 1.5rem 1rem 3rem 1rem;
  border-bottom: 1px solid #e1e1e1;
  p,
  span,
  i {
    margin: 0;
  }
  button{
    all: unset;
  }
  span {
    font-size: 1.1rem;
    font-weight: 900;
  }
  p {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  i {
    font-size: 2rem;
    color: v.$primary;

  }
}
.entrenado:last-of-type{
  border-bottom: none;

}