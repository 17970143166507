@use "base/mixins" as m;
@use "base/variables" as v;
.buscadores_alumnos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include m.desktop{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }
}
